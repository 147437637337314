<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
export default{
  data(){
    return{
      
    }
  },
  beforeDestroy(){
    Cookies.remove('token')
    window.sessionStorage.clear()
  }
}

</script>

<style lang="scss">
#app{
  width: 100%;
  height: 100%;
}
</style>
