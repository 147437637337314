import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'lib-flexible' //适配
import './assets/iconfont/iconfont.css';
import '../public/style.css'
import ElementUI, { MessageBox } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import * as echarts from 'echarts';
import moment from 'moment'
import 'quill/dist/quill.snow.css'
import md5 from 'js-md5'
import VueDOMPurifyHTML from 'vue-dompurify-html'
Vue.use(VueDOMPurifyHTML)

Vue.prototype.md5=md5

Vue.prototype.$eventBus=new Vue() //事件总线

Vue.component(CollapseTransition.name, CollapseTransition)//element内置动画

Vue.use(ElementUI,{size:'small'});

Vue.prototype.$echarts = echarts;

Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

Vue.prototype.$confirm = MessageBox.confirm;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default Vue
